// Generated by Framer (461de17)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./DpPqWfirM-0.js";

const cycleOrder = ["qEybvZM2r", "RrIYWXfBc", "e4EQtIvnJ", "DXEfEjQsa"];

const serializationHash = "framer-Mc5ny"

const variantClassNames = {DXEfEjQsa: "framer-v-l4t51f", e4EQtIvnJ: "framer-v-1d1f9wx", qEybvZM2r: "framer-v-1mj4f8w", RrIYWXfBc: "framer-v-ud7q2m"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {ZULQJWnvF: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {duration: 0, type: "tween"}

const transition2 = {delay: 0, duration: 4, ease: [0, 0, 1, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Static / Empty": "e4EQtIvnJ", "Static / Filled": "DXEfEjQsa", Empty: "qEybvZM2r", Filled: "RrIYWXfBc"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "qEybvZM2r"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "qEybvZM2r", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear1q9llf2 = activeVariantCallback(async (...args) => {
setVariant("RrIYWXfBc")
})

useOnVariantChange(baseVariant, {default: onAppear1q9llf2, DXEfEjQsa: undefined, e4EQtIvnJ: undefined})

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "DXEfEjQsa") return true
return false
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1} {...addPropertyOverrides({RrIYWXfBc: {value: transition2}}, baseVariant, gestureVariant)}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1mj4f8w", className, classNames)} data-framer-name={"Empty"} data-highlight layoutDependency={layoutDependency} layoutId={"qEybvZM2r"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({DXEfEjQsa: {"data-framer-name": "Static / Filled", "data-highlight": undefined}, e4EQtIvnJ: {"data-framer-name": "Static / Empty", "data-highlight": undefined}, RrIYWXfBc: {"data-framer-name": "Filled"}}, baseVariant, gestureVariant)}><motion.div className={"framer-14y63g2"} layoutDependency={layoutDependency} layoutId={"B3men9nsb"} style={{backgroundColor: "rgba(0, 0, 0, 0)", borderBottomLeftRadius: 6, borderBottomRightRadius: 6, borderTopLeftRadius: 6, borderTopRightRadius: 6}} variants={{DXEfEjQsa: {backgroundColor: "rgb(255, 255, 255)"}}}><motion.div className={"framer-u5ygye"} layoutDependency={layoutDependency} layoutId={"SFZfc7MBP"} style={{backgroundColor: "rgb(255, 255, 255)"}}/>{isDisplayed() && (<motion.div className={"framer-1rz8i4e"} data-framer-name={"Static"} layoutDependency={layoutDependency} layoutId={"CdpPdUPIj"} style={{backgroundColor: "rgb(255, 255, 255)"}}/>)}<motion.div className={"framer-u6cp1c"} layoutDependency={layoutDependency} layoutId={"vns3pipJT"} style={{backgroundColor: "rgb(255, 255, 255)", opacity: 0.4}}/></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Mc5ny.framer-kfv3f2, .framer-Mc5ny .framer-kfv3f2 { display: block; }", ".framer-Mc5ny.framer-1mj4f8w { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 4px; justify-content: flex-start; overflow: visible; padding: 0px; position: relative; width: 27px; }", ".framer-Mc5ny .framer-14y63g2 { align-content: center; align-items: center; display: flex; flex: 1 0 0px; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 4px; justify-content: flex-start; overflow: hidden; padding: 0px; position: relative; width: 1px; will-change: var(--framer-will-change-override, transform); }", ".framer-Mc5ny .framer-u5ygye { flex: none; height: 4px; overflow: hidden; position: relative; width: 0%; }", ".framer-Mc5ny .framer-1rz8i4e { bottom: 0px; flex: none; left: 0px; overflow: hidden; position: absolute; right: 0px; top: 0px; z-index: 3; }", ".framer-Mc5ny .framer-u6cp1c { bottom: 0px; flex: none; overflow: hidden; position: absolute; right: 0px; top: 0px; width: 100%; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Mc5ny.framer-1mj4f8w, .framer-Mc5ny .framer-14y63g2 { gap: 0px; } .framer-Mc5ny.framer-1mj4f8w > *, .framer-Mc5ny .framer-14y63g2 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-Mc5ny.framer-1mj4f8w > :first-child, .framer-Mc5ny .framer-14y63g2 > :first-child { margin-left: 0px; } .framer-Mc5ny.framer-1mj4f8w > :last-child, .framer-Mc5ny .framer-14y63g2 > :last-child { margin-right: 0px; } }", ".framer-Mc5ny.framer-v-ud7q2m.framer-1mj4f8w, .framer-Mc5ny.framer-v-1d1f9wx.framer-1mj4f8w, .framer-Mc5ny.framer-v-l4t51f.framer-1mj4f8w { width: 27px; }", ".framer-Mc5ny.framer-v-ud7q2m .framer-u5ygye { width: 100%; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 4
 * @framerIntrinsicWidth 27
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"RrIYWXfBc":{"layout":["fixed","fixed"]},"e4EQtIvnJ":{"layout":["fixed","fixed"]},"DXEfEjQsa":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerDpPqWfirM: React.ComponentType<Props> = withCSS(Component, css, "framer-Mc5ny") as typeof Component;
export default FramerDpPqWfirM;

FramerDpPqWfirM.displayName = "Story Pill";

FramerDpPqWfirM.defaultProps = {height: 4, width: 27};

addPropertyControls(FramerDpPqWfirM, {variant: {options: ["qEybvZM2r", "RrIYWXfBc", "e4EQtIvnJ", "DXEfEjQsa"], optionTitles: ["Empty", "Filled", "Static / Empty", "Static / Filled"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerDpPqWfirM, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})